@import "../../styles/variables";
@import "../../styles/mixins";

.products {
    padding-top: 0;
    overflow: hidden;

    &__header {
        animation: animateproduct alternate 9s infinite ease-in;
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 100vh;

        .products__overlay {
            margin-top: 80px;
            background: #011627a2;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            p {
                color: #fff;
                margin-bottom: 50px;
            }
        }
    }

    @keyframes animateproduct {
        0%,
        25% {
            background-size: 100%;
            background-image: url("../../resources/img/ProductsPage/Background/IMG_7963.jpg");
        }
        40%,
        65%  {
            background-size: 110%;
            background-image: url("../../resources/img/ProductsPage/Background/IMG_7998.jpg");
        }
        80%,
        100%  {
            background-size: 120%;
            background-image: url("../../resources/img/ProductsPage/Background/IMG_8001.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animateproduct {
            100%,
            0%,
            18% {
                background-size: cover;
                background-image: url("../../resources/img/ProductsPage/Background/IMG_7963.jpg");
            }
            33%,
            48% {
                background-size: cover;
                background-image: url("../../resources/img/ProductsPage/Background/IMG_7998.jpg");
            }
            63%,
            78% {
                background-size: cover;
                background-image: url("../../resources/img/ProductsPage/Background/IMG_8001.jpg");
            }
        }
    }

    h3 {
        margin-top: 50px;
        font-weight: 600;
        text-align: center;
    }

    ul {
        margin-top: 20px;
        columns: 2;

        li {
            line-height: 1.7;
            color: #535353;
        }
    }

    .col-md-3 {
        margin-bottom: 20px;
    }

    .col-md-3 img a {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }

    .col-md-3 a:hover img {
        cursor: pointer;
        opacity: 0.5;
    }

    @include mq("phone-wide") {
        &__header {
            .products__overlay {
                h1 {
                    font-size: 40px;
                }
            }
        }
    }
}
