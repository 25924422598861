@import "../../styles/variables";
@import "../../styles/mixins";

.news {
    padding-top: 0;
    overflow: hidden;

    &__header {
        animation: animatenews alternate 9s infinite ease-in;
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 100vh;

        .news__overlay {
            margin-top: 80px;
            background: #011627a2;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            span {
                color: #fff;
            }
        }
    }

    @keyframes animatenews {
        0%,
        18% {
            background-size: 100%;
            background-image: url("../../resources/img/news/news-bg1.jpg");
        }
        30%,
        48% {
            background-size: 110%;
            background-image: url("../../resources/img/news/news-bg2.jpg");
        }
        60%,
        78% {
            background-size: 120%;
            background-image: url("../../resources/img/news/news-bg3.jpg");
        }
        90%,
        100% {
            background-size: 130%;
            background-image: url("../../resources/img/news/news-bg4.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animatenews {
            0%,
            18% {
                background-size: cover;
                background-image: url("../../resources/img/news/news-bg1.jpg");
            }
            30%,
            48% {
                background-size: cover;
                background-image: url("../../resources/img/news/news-bg2.jpg");
            }
            60%,
            78% {
                background-size: cover;
                background-image: url("../../resources/img/news/news-bg3.jpg");
            }
            90%,
            100% {
                background-size: cover;
                background-image: url("../../resources/img/news/news-bg4.jpg");
            }
        }
    }

    &__section {
        padding: 80px 0;

        h2{
            margin-bottom: 25px;
            font-size: 35px;
            font-weight: bold;
        }

        p{
            font-size: 18px;
        }

        &-img {
            width: 100%;
        }
    }
}
