@import "./variables";

.button {
    padding: 15px 30px;
    border-radius: 100px;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    position: relative;
    z-index: 1;
    outline: none;
    border: 0;
    background-color: #0db8ca;
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.8;
        color: #fff;
        box-shadow: 0 0 0 0 transparent !important;
    }

    &:focus-visible {
        box-shadow: 0 0 0 2px #000 !important;
    }

    &:active {
        border: 0;
        transform: scale(0.85);
        transition: all 0.05s;
    }
}

.button-black {
    margin-top: 30px !important;
    padding: 15px 30px;
    border-radius: 100px;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    position: relative;
    z-index: 1;
    outline: none;
    border: 2px solid #fff;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover {
        background-color: #fff;
        color: #000;
        box-shadow: 0 0 0 0 transparent !important;
    }

    &:focus-visible {
        box-shadow: 0 0 0 2px #000 !important;
    }

    &:active {
        border: 0;
        transform: scale(0.85);
        transition: all 0.05s;
    }
}
