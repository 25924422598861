@import "../../styles/variables";
@import "../../styles/mixins";

.my-form {
    margin-top: 30px;

    h2 {
        margin: 22px 0;
        color: #000;
        font-size: 42px;
    }
    .MuiInputLabel-root {
        color: #000;
    }

    .Mui-focused,
    .MuiInput-input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .MuiInput-root {
        color: rgba(0, 0, 0, 0.87);
        padding-bottom: 5px;

        &::before {
            border-bottom: 1px solid rgba(0, 0, 0, 0.603) !important;
        }

        &::after {
            border-bottom: 2px solid rgba(0, 0, 0, 0.18);
        }
    }
}
