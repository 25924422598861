@import "../../styles/variables";
@import "../../styles/mixins";

.aboutsection {
    text-align: center;
    background-color: #f7f7f7;

    h2 {
        margin-top: 30px;
        font-weight: 700;
        font-size: 45px;
    }

    &__shadow {
        padding-bottom: 40px;
        box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
    }

    &__subtitle {
        margin: 0 auto 70px;
        max-width: 800px;
        color: #535353;
    }

    &__img {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 255px;
        max-height: auto;
    }

    .img1 {
        background-image: url("../../resources/img/About/IMG_7830.webp");
    }

    .img2 {
        background-image: url("../../resources/img/About/IMG_7919.webp");
    }

    .img3 {
        background-image: url("../../resources/img/About/IMG_7982.webp");
    }

    &__box {
        padding-top: 15px;
        width: 87%;
        margin: -60px auto auto;
        background-color: #f7f7f7;
        border-radius: 4px;

        h4 {
            font-weight: 600;
        }

        p {
            min-height: 105px;
            color: #535353;
        }
    }

    @include mq("desktop-wide") {
        &__box {
            p {
                min-height: 120px;
            }
        }
    }

    @include mq("desktop-small") {
        &__box {
            p {
                min-height: 145px;
            }
        }
    }
}
