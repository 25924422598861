@import "../../styles/variables";
@import "../../styles/mixins";

.about {
    padding-top: 0;
    overflow: hidden;

    &__header {
        animation: animateabout alternate 9s infinite ease-in;
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 100vh;

        .about__overlay {
            margin-top: 80px;
            background: #011627a2;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            span {
                color: #fff;
            }
        }
    }

    @keyframes animateabout {
        0%,
        25% {
            background-size: 100%;
            background-image: url("../../resources/img/About/About-bg.webp");
        }
        40%,
        65% {
            background-size: 110%;
            background-image: url("../../resources/img/About/About-bg2.jpg");
        }
        80%,
        100% {
            background-size: 120%;
            background-image: url("../../resources/img/About/About-bg3.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animateabout {
            0%,
            25% {
                background-size: cover;
                background-image: url("../../resources/img/About/About-bg.webp");
            }
            40%,
            65% {
                background-size: cover;
                background-image: url("../../resources/img/About/About-bg2.jpg");
            }
            80%,
            100% {
                background-size: cover;
                background-image: url("../../resources/img/About/About-bg3.jpg");
            }
        }
    }

    &__parag {
        padding: 40px 0;
    }

    &__service {
        font-size: 32px;
        margin-top: 55px;
        font-weight: 700;
        text-align: center;
    }

    &__us {
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &-text {
            background-color: #d6d6d6;
            padding: 30px 25px 25px 30px !important;

            h3 {
                font-weight: 600;
                text-align: center;
            }

            p {
                margin-bottom: 0;
                color: #292929;
            }

            span {
                color: #292929;
            }

            ul {
                list-style-type: none;

                li {
                    margin: 0.5rem 0;
                    color: #292929;
                    svg {
                        color: $cOne;
                    }
                }
            }
        }
    }

    .swiper-bg {
        margin-top: 50px;
        background-image: url("../../resources/img/ProductsPage/IMG_7838.webp");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;

        .row {
            padding: 80px 0;
            background: #011627a2;
            width: 100%;
            height: 100%;

            h3 {
                margin: 0 auto;
                color: #fff;
                font-size: 50px;
                width: 70%;
                line-height: 60px;
            }
        }
    }

    .swiper__img {
        .swiper-slide {
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            color: #ffffff;
            width: 60%;
            height: 400px;
            background-size: cover;

            div {
                width: 100%;
                padding: 50px;
                height: 100%;
                transition: 0.4s;

                &:hover{
                    background-color: #0b0b0ba6;
                }
            }
        }

        .swiper-slide:nth-child(1) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7957.webp");
        }

        .swiper-slide:nth-child(2) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7951.webp");
        }

        .swiper-slide:nth-child(3) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7952.webp");
        }

        .swiper-slide:nth-child(4) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7959.webp");
        }

        .swiper-slide:nth-child(5) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7966.webp");
        }

        .swiper-slide:nth-child(6) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7970.webp");
        }

        .swiper-slide:nth-child(7) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_7991.webp");
        }

        .swiper-slide:nth-child(8) {
            background-image: url("../../resources/img/ProductsPage/Products/IMG_8002.webp");
        }

        .swiper-pagination {
            bottom: 0;

            .swiper-pagination-bullet {
                background-color: #0db8ca;
            }
        }
    }

    &__pics {
        .about__img {
            position: relative;
            margin-bottom: 100px;

            &-big {
                padding: 0 30px;
                max-height: 400px;
                object-fit: cover;
                width: 100%;
                margin-bottom: 55px;
            }

            &-small {
                box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
                width: 300px;
                height: 200px;
                object-fit: cover;
                bottom: -40px;
                right: -40px;
                position: absolute;
            }
            &-small2 {
                box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);
                width: 300px;
                height: 200px;
                object-fit: cover;
                bottom: -40px;
                left: -40px;
                position: absolute;
            }
        }

        h4 {
            font-weight: 600;
            text-align: center;
        }

        p {
            padding: 10px 40px 40px 40px;
            color: #535353;
        }
    }

    &__line {
        margin-bottom: 40px;
    }

    @include mq("desktop") {
        &__pics {
            .about__img {
                position: relative;
                margin-bottom: 0;

                &-big {
                    padding: 0 30px;
                    max-height: 400px;
                    object-fit: cover;
                    width: 100%;
                    margin-bottom: 55px;
                }

                &-small,
                &-small2 {
                    display: none;
                }
            }
        }
    }

    @include mq("tablet") {
        .swiper {
            .swiper-slide {
                font-size: 20px;
                font-weight: 400;
                line-height: 32px;
                color: #868686;
                width: 100%;
                height: 400px;
                padding: 50px;
            }
        }
    }

    @include mq("phone-wide") {
        &__header {
            .about__overlay {
                h1 {
                    font-size: 50px;
                }
            }
        }
    }
}
