@import "../../styles/variables";
@import "../../styles/mixins";

.svgmap {
    h2 {
        display: inline-block;
        margin: 60px 0 40px;
        position: relative;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        font-size: 45px;
        cursor: pointer;
    }

    h5 {
        cursor: pointer;
        margin: 0 auto 10px;
        display: table;
        color: #2b2b2b;
    }

    #myWindow {
        circle {
            cursor: pointer;
        }

        text {
            cursor: context-menu;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
        }

        .circlexx {
            opacity: 0;
            fill: #c0c0c0;
            stroke: #000000;
            stroke-width: 0.5;
        }
        .subxx {
            opacity: 0;
            fill: #c0c0c0;
            stroke: #000000;
            stroke-width: 0.3;
        }
        .landxx {
            fill: #c0c0c0;
            stroke: #ffffff;
            stroke-width: 0.5;
            fill-rule: evenodd;
        }
        .coastxx {
            width: 0.2;
        }
        .antxx {
            opacity: 1;
            fill: #c0c0c0;
        }
        .noxx {
            opacity: 0;
            fill: #c0c0c0;
            stroke: #000000;
            stroke-width: 0.5;
        }
        .limitxx {
            opacity: 0;
            fill: #c0c0c0;
            stroke: #ffffff;
            stroke-width: 0.2;
            fill-rule: evenodd;
        }
        .unxx {
            opacity: 0;
            fill: #c0c0c0;
            stroke: #000000;
            stroke-width: 0.3;
        }
        .oceanxx {
            opacity: 1;
            fill: #ffffff;
            stroke: #000000;
            stroke-width: 0.5;
        }
        #ru-line {
            display: none;
        }
        #kg-line {
            display: none;
        }
        #by-line {
            display: none;
        }
        #pl-line {
            display: none;
        }
        #ua-line {
            display: none;
        }

        #it-line {
            display: none;
        }

        #ru-marker:hover ~ #ru-line {
            display: block;
        }

        #kg-marker:hover ~ #kg-line {
            display: block;
        }
        #by-marker:hover ~ #by-line {
            display: block;
        }
        #pl-marker:hover ~ #pl-line {
            display: block;
        }
        #ua-marker:hover ~ #ua-line {
            display: block;
        }
        #it-marker:hover ~ #it-line {
            display: block;
        }
    }

    .russian__hover,
    .ukraine__hover,
    .belarus__hover,
    .poland__hover,
    .italy__hover,
    .kyrgyzstan__hover {
        transition: 0.3s;
        fill: #0db8ca;
    }

    #uz {
        fill: #0db8ca!important;
    }

    @include mq("phone-wide") {
        h2 {
            text-align: center;
            font-size: 22px !important;
        }
    }
}
