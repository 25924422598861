@import "../../styles/variables";
@import "../../styles/mixins";

.intro {
    padding-top: 0;
    overflow: hidden;

    &__header {
        animation: animate alternate 9s infinite ease-in;
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 100vh;

        .intro__overlay {
            margin-top: 80px;
            background: #01162736;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            h1 {
                font-weight: 600;
                color: #fff;
                font-size: 70px;
            }

            p {
                font-size: 22px;
                margin: 20px auto 30px;
                max-width: 600px;
                line-height: 1.8;
                font-weight: 400;
                color: rgb(255, 255, 255);
            }
        }
    }
    @keyframes animate {
        0%,
        25% {
            background-size: 100%;
            background-image: url("../../resources/img/intro.jpg");
        }
        40%,
        65% {
            background-size: 110%;
            background-image: url("../../resources/img/IMG_7802 .jpg");
        }
        80%,
        100% {
            background-size: 120%;
            background-image: url("../../resources/img/IMG_7848.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animate {
            100%,
            0%,
            18% {
                background-size: cover;
                background-image: url("../../resources/img/intro.jpg");
            }
            33%,
            48% {
                background-size: cover;
                background-image: url("../../resources/img/IMG_7802 .jpg");
            }
            63%,
            78% {
                background-size: cover;
                background-image: url("../../resources/img/IMG_7848.jpg");
            }
        }
    }

    @include mq("phone-wide") {
        &__header {
            .intro__overlay {
                h1 {
                    font-size: 40px;
                }

                p {
                    margin: 10px auto 20px;
                    font-size: 20px;
                }
            }
        }
    }
}
