// =============================> Mons

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-Regular.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mons";
    src: url("../resources/fonts/Mons/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

