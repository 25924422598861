@import "../../styles/variables";
@import "../../styles/mixins";

.partnerssection {
    padding-bottom: 50px;
    h2 {
        font-weight: 700;
        font-size: 45px;
        text-align: center;
    }

    &__box {
        width: 50% !important;
        display: flex;
        height: 250px;
        max-height: 250px;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        img {
            width: 100%;
            transition: 0.5s;
        }
    }
}
