@import "../../styles/variables";
@import "../../styles/mixins";

.productsection {
    text-align: center;

    h6 {
        margin-bottom: 0;
    }

    h2 {
        font-weight: 700;
        font-size: 50px;
        margin-bottom: 25px;
    }

    .swiper-pagination {
        bottom: -3px !important;
    }

    .col-md-4 {
        width: 90%;

        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .col-md-4 img a {
        opacity: 1;
        transition: 0.3s ease-in-out;
    }

    .col-md-4 a:hover img {
        cursor: pointer;
        opacity: 0.5;
    }

    .callback {
        margin-top: 50px;
        height: 400px;
        background-color: #131212;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h6 {
            color: #535353;
        }

        h2 {
            margin-bottom: 40px;
        }
    }
}
