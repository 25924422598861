@import "../../styles/variables";
@import "../../styles/mixins";

.contactssection {
    min-height: 540px;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../resources/img/Contacts/IMG_7919.jpg");

    &-overlay {
        min-height: 540px;
        padding: 1px 0;
        backdrop-filter: blur(1px);
        background-color: rgba(192, 192, 192, 0.518);
        overflow: hidden;
    }
}
