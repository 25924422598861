@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
    background-color: #f7f7f7;
    color: rgba(0, 0, 0, 0.737);
    margin-top: auto;
    padding: 20px 0 10px;

    h3 {
        font-size: 23px;
        color: rgba(0, 0, 0, 0.936);
    }

    &__link {
        display: flex;
        flex-direction: column;

        a {
            margin-top: 9px;

            &:hover {
                color: #0db8ca;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 20px !important;
    }

    @include mq("tablet") {
        .col-md-4 {
            text-align: center;
            margin-bottom: 30px;

            &::after {
                content: "";
                display: block;
                height: 2px;
                background-color: #dcdcdc;
                width: 60px;
                margin: 25px auto 0;
            }
        }
    }

    @include mq("phone-wide") {
        &__bottom {
            margin-top: 40px !important;
            flex-direction: column;
            align-items: center;
            line-height: 2;
        }
    }
}
