@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
    &__mission {
        justify-content: center;
        padding: 70px 0 0;

        h2 {
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            margin-bottom: 30px;
        }

        .col-md-5 {
            margin-bottom: 30px;
        }

        &-box {
            border-radius: 10px;
            padding: 35px 35px 0 35px;
            height: 100%;
            box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 22%);

            svg {
                margin-bottom: 15px;
                font-size: 55px;
            }

            h3 {
                font-size: 25px;
                margin-bottom: 20px;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 1.8;
                color: #535353;
            }
        }
    }
}
