@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
    padding-top: 0;
    overflow: hidden;

    &__header {
        background-image: url("../../resources/img/Contacts/contacts.webp");
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 85vh;

        .contacts__overlay {
            margin-top: 80px;
            background: #011627a2;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            span {
                color: #fff;
            }
        }
    }

    h6 {
        margin-top: 50px;
        text-align: center;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-content: center;

        svg {
            font-size: 3rem;
        }

        h2 {
            font-weight: 600;
            font-size: 40px;
        }
    }

    &__adress {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        h5 {
            font-size: 18px;
            color: #535353;
            margin: 0 50px;
        }
    }

    @include mq("tablet-small") {
        &__header {
            .contacts__overlay {
                h1 {
                    font-size: 45px;
                }
            }
        }
    }
}
